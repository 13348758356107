import { LOGOUT_URL } from 'constants/user';
import { APP_KEY, SSO_URL } from 'common/config';

export function logoutUser() {
  window.location = LOGOUT_URL;
}

export function isMemberOf(user, targetGroups) {
  const { groups = [] } = user || {};
  if (!groups?.length) {
    return false;
  }
  return targetGroups.some(targetGroup => groups.includes(targetGroup));
}

// TODO redirect to login SSO page
export function toLoginPage() {
  window.location = `${SSO_URL}/auth/login/${APP_KEY}`;
}
